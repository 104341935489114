import { generateMapByOpts } from 'common/utils'

// 同步状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '成功',
    value: 1
  },
  {
    text: '失败',
    value: 0
  }
]

const statusMap = generateMapByOpts(statusOps)

export { statusOps, statusMap }
