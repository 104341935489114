var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "syncDetailList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          extraParams: _vm.extraParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "任务名称", maxlength: 100 },
                  model: {
                    value: _vm.searchParams.syncTypeName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "syncTypeName", $$v)
                    },
                    expression: "searchParams.syncTypeName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "数据编号", maxlength: 100 },
                  model: {
                    value: _vm.searchParams.syncNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "syncNo", $$v)
                    },
                    expression: "searchParams.syncNo",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "同步状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.syncStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "syncStatus", $$v)
                    },
                    expression: "searchParams.syncStatus",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "同步时间段",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                scope.row.syncMessage
                  ? _c("v-button", {
                      attrs: {
                        text: "查看日志信息",
                        type: "text",
                        permission: "info",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showLogInfo(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }