<template>
  <div class="syncDetailList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :extraParams="extraParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-input label="任务名称" v-model="searchParams.syncTypeName" :maxlength="100"></v-input>
        <v-input label="数据编号" v-model="searchParams.syncNo" :maxlength="100"></v-input>
        <v-select label="同步状态" v-model="searchParams.syncStatus" :options="statusOps"></v-select>
        <v-datepicker
          label="同步时间段"
          type="rangedatetimer"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
        ></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="查看日志信息"
          type="text"
          permission="info"
          v-if="scope.row.syncMessage"
          @click="showLogInfo(scope.row)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL } from './api'
import { statusOps, statusMap } from './map'

export default {
  name: 'SyncDetailList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        syncTypeName: '',
        syncNo: '',
        syncStatus: undefined,
        startTime: '',
        endTime: ''
      },
      extraParams: {},
      statusOps,
      headers: [
        {
          prop: 'syncTypeName',
          label: '任务名称'
        },
        {
          prop: 'syncNo',
          label: '数据编号'
        },
        {
          prop: 'syncStatus',
          label: '同步状态',
          formatter (row) {
            return statusMap[row.syncStatus]
          }
        },
        {
          prop: 'syncTime',
          label: '同步时间'
        },
        {
          prop: 'syncCost',
          label: '同步时长',
          align: 'right'
        }
      ]
    }
  },
  created () {
    let queryData = this.$route.query
    this.extraParams.startTime = queryData.startTime || ''
    this.extraParams.endTime = queryData.endTime || ''
    this.extraParams.syncTypeName = queryData.syncTypeName || ''
  },
  methods: {
    showLogInfo (row) {
      let content = '<div style="max-height: 400px; overflow-y: auto; word-wrap: break-word">' + row.syncMessage + '</div>'
      this.$alert(content, {
        title: '',
        closeOnClickModal: true,
        showConfirmButton: false,
        showClose: true,
        dangerouslyUseHTMLString: true
      })
    }
  }
}
</script>
